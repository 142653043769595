<script lang="ts" setup>
const {
  leadId,
  notes = [],
  size,
} = defineProps<{
  leadId: string
  notes: Note[]
  size?: 'small'
}>()

const note = ref<Note>({ $id: undefined, createdAt: undefined, text: '' })

const isEditing = ref(false)
function closeEdit() {
  isEditing.value = false
}
const isPending = ref(false)

const filter = ref('all')
const filteredNotes = computed(() => {
  if (filter.value === 'all')
    return notes

  return notes.filter(note => note.type === filter.value)
})

const expanded = ref<string[]>([])

async function createNote() {
  try {
    isPending.value = true
    await useLeadStore().updateLeadNotes(leadId, note.value)
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    note.value = { $id: undefined, createdAt: undefined, text: '' }
    isEditing.value = false
  }
}

async function updateNote(noteItem: Note) {
  try {
    isPending.value = true
    await useLeadStore().updateLeadNotes(leadId, {
      $id: noteItem.$id,
      text: noteItem.text,
    })
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    expanded.value = []
    isEditing.value = false
  }
}
async function deleteNote(id: string) {
  try {
    isPending.value = true
    await useLeadStore().deleteLeadNote(leadId, id as string)
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    note.value = { $id: undefined, createdAt: undefined, text: '' }
    isEditing.value = false
  }
}

const headers = [
  { key: 'createdAt', title: 'erstellt' },
  { key: 'text', title: 'Text' },
  { align: 'end', key: '$id', title: 'Aktion', width: 110 },
]
function itemRowBackground({ index, item }: { item: any, index: number }): { class: string, style?: string } {
  return {
    class: [
      index % 2 === 0
        ? 'odd'
        : 'even',
      item.$id === expanded.value[0] ? 'expanded' : '',
    ],
  }
}

const finalHeader = computed(() => {
  if (size)
    return headers.filter(h => h.key !== 'createdAt')

  return header
})
</script>

<template>
  <div
    class=" d-flex flex-column"
    :class="{
      'pa-4': !size,
      'h-100': !size,
      'flex-grow-1': !size,
    }"
    :rounded="size ? '0' : 'xl'"
    variant="flat"
  >
    <v-card-title
      class="py-2 font-weight-light d-flex align-center justify-space-between"
      :class="{ 'mb-4': !size }"
      style="min-height:52px"
    >
      <div class="d-flex align-center">
        Meine Notizen
      </div>

      <v-fade-transition leave-absolute hide-on-leave group>
        <div
          v-if="!isEditing"
          key="filter"
        >
          <v-btn
            v-if="notes?.length > 0"
            aria-label="alle Notizen anzeigen"
            :color="filter === 'default' ? 'primary' : 'default'"
            icon
            size="x-small"
            variant="text"
            @click="filter = filter === 'default' ? 'all' : 'default'"
          >
            <Icon
              :class="{ 'text-medium-emphasis': filter !== 'default' }"
              name="tabler:file-text"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              text="alle Notizen anzeigen"
            />
          </v-btn>
          <v-btn
            v-if="notes?.length > 0"
            aria-label="Anruf Versuche anzeigen"
            :color="filter === 'callAttempt' ? 'primary' : 'default'"
            icon
            size="x-small"
            variant="text"
            @click="filter = filter === 'callAttempt' ? 'all' : 'callAttempt'"
          >
            <Icon
              :class="{ 'text-medium-emphasis': filter !== 'callAttempt' }"
              name="tabler:file-phone"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              text="Anruf Versuche anzeigen"
            />
          </v-btn>
          <v-btn
            v-if="!isEditing"
            key="add"
            aria-label="Notiz anlegen"
            class="mr-n4 ml-4"
            icon
            size="x-small"
            variant="text"
            @click="isEditing = true"
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:plus"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz anlegen"
            />
          </v-btn>
        </div>
        <v-btn
          v-else-if="isEditing"
          aria-label="schließen"
          class="mr-n4"
          icon
          size="x-small"
          variant="text"
          @click="closeEdit"
        >
          <Icon
            class="text-medium-emphasis"
            name="tabler:x"
            size="18"
          />
        </v-btn>
      </v-fade-transition>
    </v-card-title>
    <v-fade-transition leave-absolute hide-on-leave>
      <v-data-table
        v-if="filteredNotes?.length > 0"
        class="text-caption"
        destiny="compact"
        :headers="headers"
        :items="filteredNotes"
        item-value="$id"
        :items-length="filteredNotes.length"
        :loading="isPending"
        loading-text=""
        :hover="false"
        :row-props="itemRowBackground"
        :expanded="expanded"
        :sort-by="[{ key: 'createdAt', order: 'desc' }]"
      >
        <template #expanded-row="{ columns, item }">
          <tr class="expanded">
            <td :colspan="columns.length" class="pa-4 pt-0">
              <v-row class="pa-4">
                <v-divider class="mb-4" color="surface-variant" />
                <v-col cols="12" class="pa-0 pr-2">
                  <v-textarea
                    v-model="item.text"
                    auto-grow
                    density="compact"
                    hide-details
                    placeholder="Beschreibe deine Aufgabe"
                    rounded="lg"
                    rows="3"
                    max-rows="5"
                  >
                    <template #append-inner>
                      <v-btn
                        icon
                        :loading="isPending"
                        size="small"
                        variant="text"
                        style="position:absolute;right: 8px;bottom: 8px;"
                        @click="updateNote(item)"
                      >
                        <Icon
                          name="tabler:device-floppy"
                          size="24"
                        />
                      </v-btn>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template #headers />
        <template #loader>
          <ClientOnly>
            <Teleport to=".v-table__wrapper">
              <div class="v-table-loader d-flex align-center justify-center w-100">
                <Icon
                  class="text-primary" name="svg-spinners:pulse-multiple" size="52"
                />
              </div>
            </Teleport>
          </ClientOnly>
        </template>
        <template #bottom />

        <template #[`item.createdAt`]="{ value }">
          <span class="font-weight-light">
            {{ useDateFns().untilNow(value as Date, { short: size ? true : false }) }}
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              :text="useDateFns().formatDate(value as Date)"
            />
          </span>
        </template>

        <template #[`item.text`]="{ value }">
          <span class="font-weight-medium">{{ value }}</span>
        </template>

        <template #[`item.$id`]="{ value }">
          <v-btn
            v-if="value === expanded[0]"
            aria-label="anrufen"
            icon
            :loading="isPending"
            size="x-small"
            variant="text"
            @click="deleteNote(value as string)"
          >
            <Icon
              name="tabler:trash"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz bearbeiten"
            />
          </v-btn>
          <v-btn
            aria-label="Notiz bearbeiten"
            icon
            size="x-small"
            variant="text"
            @click="expanded = expanded.includes(value as string) ? [] : [value as string]"
          >
            <Icon
              v-if="value === expanded[0]"
              name="tabler:x"
              size="18"
            />
            <Icon
              v-else
              name="tabler:pencil"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz bearbeiten"
            />
          </v-btn>
        </template>
      </v-data-table>
      <div
        v-else
        class="d-flex flex-column align-center justify-center ga-2"
        :class="{ 'flex-grow-1': !size }"
      >
        <Icon
          v-if="!size"
          class="text-disabled"
          name="tabler:notes"
          size="54"
        />
        <span
          class="font-weight-light text-disabled"
          :class="{ 'text-body-1': !size }"
        >
          Kein Notizen vorhanden
        </span>
        <v-btn
          v-if="!size && !isEditing"
          aria-label="Lead reklamieren"
          class="text-body-1"
          variant="text"

          @click="isEditing = true"
        >
          Jetzt anlegen
        </v-btn>
      </div>
    </v-fade-transition>
    <v-fade-transition leave-absolute hide-on-leave>
      <div
        v-if="isEditing"
        class="mt-auto"
      >
        <v-row class="pa-4 mt-4">
          <v-col cols="12" class="pa-0 pr-2">
            <v-textarea
              v-model="note.text"
              autofocus
              auto-grow
              hide-details
              placeholder="Schreibe Deinen Notizen"
              rounded="lg"
              rows="3"
              max-rows="5"
            >
              <template #append-inner>
                <v-btn
                  icon
                  :loading="isPending"
                  size="small"
                  variant="text"
                  style="position:absolute;right: 8px;bottom: 8px;"
                  @click="createNote"
                >
                  <Icon
                    name="tabler:device-floppy"
                    size="24"
                  />
                </v-btn>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>

<style lang="scss" scoped>
</style>
