<script lang="ts" setup>
const {
  leadId,
  size,
  tasks = [],
} = defineProps<{
  leadId: string
  tasks: Task[]
  size?: 'small'
}>()

const task = ref<Task>({
  $id: undefined,
  description: null,
  expirationAt: null,
  priority: 'medium',
  status: 'open',
  title: '',
  type: 'default',
})
function resetTask() {
  task.value = {
    $id: undefined,
    description: null,
    expirationAt: null,
    priority: 'medium',
    status: 'open',
    title: '',
    type: 'default',
  }
}

const isEditing = ref(false)
function closeEdit() {
  isEditing.value = false
  resetTask()
}
const isPending = ref(false)

const filter = ref('all')
const filteredTasks = computed(() => {
  if (filter.value === 'all')
    return tasks

  return tasks.filter(task => task.type === filter.value)
})

const expanded = ref<string[]>([])

async function updateTask(taskItem: Task) {
  try {
    isPending.value = true
    await useLeadStore().updateLeadTask(leadId, {
      $id: taskItem.$id,
      description: taskItem.description,
      expirationAt: taskItem.expirationAt,
      priority: taskItem.priority,
      status: taskItem.status,
      title: taskItem.title,
      type: taskItem.type,
    })
  }
  catch (error) {

  }
  finally {
    expanded.value = []
    isPending.value = false
  }
}

async function createTask() {
  try {
    isPending.value = true
    await useLeadStore().createLeadTask(leadId, task.value)
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    closeEdit()
  }
}

async function deleteTask(id: string) {
  try {
    isPending.value = true
    await useLeadStore().deleteLeadTask(leadId, id as string)
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    task.value = { $id: undefined, createdAt: undefined, text: '' }
    isEditing.value = false
  }
}

const headers = [
  { key: 'status', title: 'Status', width: 80 },
  { key: 'expirationAt', title: 'Fällig' },
  { key: 'title', title: 'Titel' },
  { align: 'end', key: '$id', title: 'Aktion', width: 110 },
]

const finalHeader = computed(() => {
  if (size)
    return headers.filter(h => h.key !== 'expirationAt')

  return header
})

function itemRowBackground({ index, item }: { item: any, index: number }): { class: string, style?: string } {
  return {
    class: [
      index % 2 === 0
        ? 'odd'
        : 'even',
      item.$id === expanded.value[0] ? 'expanded' : '',
    ],
  }
}

const sortBy = ref([{ key: 'expirationAt', order: 'desc' }])
</script>

<template>
  <div
    class=" d-flex flex-column"
    :class="{
      'pa-4': !size,
      'h-100': !size,
      'flex-grow-1': !size,
    }"
    :rounded="size ? '0' : 'xl'"
    variant="flat"
  >
    <v-card-title
      class="py-2 font-weight-light d-flex align-center justify-space-between"
      :class="{ 'mb-4': !size }"
      style="min-height:52px"
    >
      <div class="d-flex align-center">
        Meine Aufgaben
      </div>

      <v-fade-transition leave-absolute hide-on-leave group>
        <div
          v-if="!isEditing"
          key="filter"
        >
          <v-btn
            v-if="tasks?.length > 0"
            aria-label="alle Aufgabe anzeigen"
            :color="filter === 'default' ? 'primary' : 'default'"
            icon
            size="x-small"
            variant="text"
            @click="filter = filter === 'default' ? 'all' : 'default'"
          >
            <Icon
              :class="{ 'text-medium-emphasis': filter !== 'default' }"
              name="tabler:file-text"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              text="alle Aufgabe anzeigen"
            />
          </v-btn>
          <v-btn
            v-if="tasks?.length > 0"
            aria-label="Anruf Versuche anzeigen"
            :color="filter === 'call' ? 'primary' : 'default'"
            icon
            size="x-small"
            variant="text"
            @click="filter = filter === 'call' ? 'all' : 'call'"
          >
            <Icon
              :class="{ 'text-medium-emphasis': filter !== 'call' }"
              name="tabler:file-phone"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              text="Anruf Versuche anzeigen"
            />
          </v-btn>
          <v-btn
            v-if="!isEditing"
            key="add"
            aria-label="Notiz anlegen"
            class="mr-n4 ml-4"
            icon
            size="x-small"
            variant="text"
            @click="isEditing = true"
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:plus"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz anlegen"
            />
          </v-btn>
        </div>
        <v-btn
          v-else-if="isEditing"
          aria-label="schließen"
          class="mr-n4"
          icon
          size="x-small"
          variant="text"
          @click="closeEdit"
        >
          <Icon
            class="text-medium-emphasis"
            name="tabler:x"
            size="18"
          />
        </v-btn>
      </v-fade-transition>
    </v-card-title>

    <v-fade-transition leave-absolute hide-on-leave>
      <v-data-table
        v-if="filteredTasks?.length > 0"
        class="text-caption"
        destiny="compact"
        :headers="headers"
        :items="filteredTasks"
        item-value="$id"
        :items-length="filteredTasks.length"
        :loading="isPending"
        loading-text=""
        :hover="false"
        :row-props="itemRowBackground"
        :expanded="expanded"
        :sort-by="[{ key: 'expirationAt', order: 'asc' }]"
      >
        <template #expanded-row="{ columns, item }">
          <tr class="expanded">
            <td :colspan="columns.length" class="pa-4 pt-0">
              <v-row class="pa-4">
                <v-divider class="mb-4" color="surface-variant" />
                <v-col cols="6" class="pa-0 pr-1">
                  <v-select
                    v-model="item.status"
                    class="mb-2"
                    :items="[
                      { title: 'Offen', value: 'open' },
                      { title: 'In Bearbeitung', value: 'in-progress' },
                      { title: 'Erledigt', value: 'completed' },
                      { title: 'Abgebrochen', value: 'canceled' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Status"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pl-1">
                  <v-select
                    v-model="item.priority"
                    class="mb-2"
                    :items="[
                      { title: 'gering', value: 'low' },
                      { title: 'normal', value: 'medium' },
                      { title: 'hoch', value: 'high' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Status"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pr-1">
                  <v-select
                    v-model="item.type"
                    class="mb-2"
                    :items="[
                      { title: 'Standard', value: 'default' },
                      { title: 'Anruf', value: 'call' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Typ"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pl-1" style="height:48px">
                  <InputDateTime
                    :date-props="{ min: new Date() }"
                    :menu-props="{ location: 'bottom center', width: '328px' }"
                    :loading="isPending"
                    @update:model-value="item.expirationAt = $event.toISOString() as Date"
                  >
                    <v-btn
                      block
                      color="default"
                      height="40px"
                      rounded="lg"
                      variant="outlined"
                    >
                      {{ item.expirationAt ? useDateFns().formatDate(item.expirationAt, 'dd.MM.yyyy HH:mm') : 'Datum eingeben' }}
                    </v-btn>
                  </InputDateTime>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    v-model="item.title"
                    class="mb-2"
                    density="compact"
                    autofocus
                    hide-details
                    placeholder="Titel"
                    rounded="lg"
                  />
                  <v-textarea
                    v-model="item.description"
                    auto-grow
                    density="compact"
                    hide-details
                    placeholder="Beschreibe deine Aufgabe"
                    rounded="lg"
                    rows="3"
                    max-rows="5"
                  >
                    <template #append-inner>
                      <v-btn
                        icon
                        :loading="isPending"
                        size="small"
                        variant="text"
                        style="position:absolute;right: 8px;bottom: 8px;"
                        @click="updateTask(item)"
                      >
                        <Icon
                          name="tabler:device-floppy"
                          size="24"
                        />
                      </v-btn>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template #headers />
        <template #loader>
          <ClientOnly>
            <Teleport to=".v-table__wrapper">
              <div class="v-table-loader d-flex align-center justify-center w-100">
                <Icon
                  class="text-primary" name="svg-spinners:pulse-multiple" size="52"
                />
              </div>
            </Teleport>
          </ClientOnly>
        </template>
        <template #bottom />
        <template #[`item.status`]="{ value, item: { priority } }">
          <div class="d-flex align-center ga-2">
            <span>
              <Icon
                v-if="value === 'completed'"
                class="text-success"
                name="tabler:progress-check"
                size="24"
              />
              <Icon
                v-else-if="value === 'canceled'"
                class="text-error"
                name="tabler:progress-x"
                size="24"
              />
              <Icon
                v-else-if="value === 'in-progress'"
                class="text-warning"
                name="tabler:progress"
                size="24"
              />
              <Icon
                v-else
                name="tabler:circle"
                size="24"
              />
            </span>
            <span>
              <Icon
                v-if="priority === 'high'"
                class="text-success"
                name="tabler:arrow-big-up-lines-filled"
                size="24"
              />
              <Icon
                v-else-if="priority === 'low'"
                class="text-error"
                name="tabler:arrow-big-down-lines-filled"
                size="24"
              />
            </span>
          </div>
          <!--  -->
        </template>

        <template #[`item.expirationAt`]="{ value }">
          <span class="font-weight-light text-no-wrap">
            {{ useDateFns().untilNow(value as Date, { short: size ? true : false }) }}
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="top"
              :text="useDateFns().formatDate(value as Date)"
            />
          </span>
        </template>

        <template #[`item.title`]="{ value, item: { description } }">
          <div class="py-4">
            <span class="font-weight-medium">{{ value }}</span><br>
            <span class="font-weight-light" v-html="description" />
          </div>
        </template>

        <template #[`item.$id`]="{ value }">
          <v-btn
            v-if="value === expanded[0]"
            aria-label="anrufen"
            icon
            :loading="isPending"
            size="x-small"
            variant="text"
            @click="deleteTask(value as string)"
          >
            <Icon
              name="tabler:trash"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz bearbeiten"
            />
          </v-btn>
          <v-btn
            aria-label="Notiz bearbeiten"
            icon
            size="x-small"
            variant="text"
            @click="expanded = expanded.includes(value as string) ? [] : [value as string]"
          >
            <Icon
              v-if="value === expanded[0]"
              name="tabler:x"
              size="18"
            />
            <Icon
              v-else
              name="tabler:pencil"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Notiz bearbeiten"
            />
          </v-btn>
        </template>
      </v-data-table>
      <div
        v-else
        class="d-flex flex-column align-center justify-center ga-2"
        :class="{ 'flex-grow-1': !size }"
      >
        <Icon
          v-if="!size"
          class="text-disabled"
          name="tabler:calendar-event"
          size="54"
        />
        <span
          class="font-weight-light text-disabled"
          :class="{ 'text-body-1': !size }"
        >
          Kein Aufgaben vorhanden
        </span>
        <v-btn
          v-if="!size && !isEditing"
          aria-label="Lead reklamieren"
          class="text-body-1"
          variant="text"

          @click="isEditing = true"
        >
          Jetzt anlegen
        </v-btn>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div
        v-if="isEditing"
        class="mt-auto"
      >
        <v-row class="pa-4 mt-4">
          <v-col cols="6" class="pa-0 pr-1">
            <v-select
              v-model="task.status"
              class="mb-2"
              :items="[
                { title: 'Offen', value: 'open' },
                { title: 'In Bearbeitung', value: 'in-progress' },
                { title: 'Erledigt', value: 'completed' },
                { title: 'Abgebrochen', value: 'canceled' },
              ]"
              destiny="compact"
              hide-details
              label="Status"
              item-value="value"
              item-title="title"
              rounded="lg"
              density="compact"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-1">
            <v-select
              v-model="task.priority"
              class="mb-2"
              :items="[
                { title: 'gering', value: 'low' },
                { title: 'normal', value: 'medium' },
                { title: 'hoch', value: 'high' },
              ]"
              destiny="compact"
              hide-details
              label="Priorität"
              item-value="value"
              item-title="title"
              rounded="lg"
              density="compact"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pr-1">
            <v-select
              v-model="task.type"
              class="mb-2"
              :items="[
                { title: 'Standard', value: 'default' },
                { title: 'Anruf', value: 'call' },
              ]"
              destiny="compact"
              hide-details
              label="Typ"
              item-value="value"
              item-title="title"
              rounded="lg"
              density="compact"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-1" style="height:48px">
            <InputDateTime
              :date-props="{ min: new Date() }"
              :menu-props="{ location: 'bottom center', width: '328px' }"
              :loading="isPending"
              @update:model-value="task.expirationAt = $event.toISOString() as Date"
            >
              <v-btn
                block
                color="default"
                height="40px"
                rounded="lg"
                variant="outlined"
              >
                {{ task.expirationAt ? useDateFns().formatDate(task.expirationAt, 'dd.MM.yyyy HH:mm') : 'Datum eingeben' }}
              </v-btn>
            </InputDateTime>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="task.title"
              class="mb-2"
              density="compact"
              autofocus
              hide-details
              placeholder="Titel"
              rounded="lg"
            />
            <v-textarea
              v-model="task.description"
              auto-grow
              density="compact"
              hide-details
              placeholder="Beschreibe deine Aufgabe"
              rounded="lg"
              rows="3"
              max-rows="5"
            >
              <template #append-inner>
                <v-btn
                  icon
                  :loading="isPending"
                  size="small"
                  variant="text"
                  style="position:absolute;right: 8px;bottom: 8px;"
                  @click="createTask()"
                >
                  <Icon
                    name="tabler:device-floppy"
                    size="24"
                  />
                </v-btn>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>

<style lang="scss" scoped>

</style>
