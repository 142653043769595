<script lang="ts" setup>
// const {
//   lead,
// } = defineProps<{
//   lead: Lead
// }>()

const lead = defineModel<Lead>({ required: true })

const { currentState, isNextState, nextState } = useLead()

const isOpen = ref(false)
const createdAt = ref()

const value = ref()
const successfully = ref()
const reason = ref()
const reasonText = ref()

async function createState(e: Date) {
  isOpen.value = false

  await useLeadStore().updateLeadStatus(
    lead.value.$id,
    {
      createdAt: e,
      level: nextState(lead.value.states).level,
      reason: `${reason.value}${reason.value === 'Sonstiges' && reasonText.value ? `: ${reasonText.value}` : ''}`,
      state: nextState(lead.value.states).state,
      successfully: successfully.value,
      value: value.value,
    } as State,
  )
  resetValues()
}

function resetValues() {
  createdAt.value = undefined
  successfully.value = undefined
  value.value = undefined
  reason.value = undefined
  reasonText.value = undefined
}

watch(isOpen, () => {
  if (!isOpen.value)
    resetValues()
})
</script>

<template>
  <v-btn v-if="isNextState(lead.states)">
    {{ nextState(lead.states).task }}

    <v-menu
      v-model="isOpen"
      activator="parent"
      :close-on-content-click="false"
      location="bottom center"
    >
      <v-card
        class="rounded-lg"
        width="344"
      >
        <v-card-text class="pb-0">
          <v-fade-transition hide-on-leave>
            <div
              v-if="nextState(lead.states).state === 'abgeschlossen' && successfully === undefined"
              class="ga-4 pb-4 d-flex flex-column align-center justify-center text-body-1 font-weight-medium"
            >
              War der Abschluss Erfolgreich?
              <div class="ga-4 d-flex align-center">
                <v-btn
                  aria-label="Guter Lead"
                  icon
                  variant="text"
                  @click="successfully = true"
                >
                  <Icon
                    class="text-success"
                    name="tabler:thumb-up-filled"
                    size="24"
                  />
                </v-btn>
                <v-btn
                  aria-label="Erinnerung anlegen"
                  icon
                  variant="text"
                  @click="successfully = false"
                >
                  <Icon
                    class="text-error"
                    name="tabler:thumb-down-filled"
                    size="24"
                  />
                </v-btn>
              </div>
            </div>
            <div
              v-if="successfully === true || successfully === false || nextState(lead.states).state !== 'abgeschlossen'"
            >
              <v-text-field
                v-if="successfully === true"
                v-model="value"
                class="mx-1 mb-4"
                density="compact"
                hide-details
                rounded="lg"
                placeholder="525,25"
                required
                label="Monatsbeitrag"
              >
                <template #prepend-inner>
                  <Icon name="tabler:currency-euro" size="24" />
                </template>
              </v-text-field>
              <div
                v-if="successfully === false"
              >
                <v-select
                  v-model="reason"
                  class="mx-1 mb-4"
                  density="compact"
                  hide-details
                  :items="[
                    'Bonität',
                    'Gesundheitliche Einschränkungen',
                    'Hat bei Mitbewerber abgeschlossen',
                    'Nicht versicherbar',
                    'Sonstiges',
                    'Zu geringes Einkommen',
                  ]"
                  rounded="lg"
                  required
                  label="Grund"
                />
                <v-textarea
                  v-if="reason === 'Sonstiges'"
                  v-model="reasonText"
                  class="mx-1 mb-4"
                  density="compact"
                  hide-details
                  rounded="lg"
                  required
                  label="Details"
                  rows="2"
                />
              </div>
              <v-fade-transition hide-on-leave>
                <LeadStateDateInput
                  v-if="
                    (nextState(lead.states).state === 'abgeschlossen' && (successfully === true && value))
                      || (nextState(lead.states).state === 'abgeschlossen'
                        && (successfully === false && reason === 'Sonstiges' && reasonText))
                      || (nextState(lead.states).state === 'abgeschlossen'
                        && (successfully === false && reason && reason !== 'Sonstiges'))
                      || nextState(lead.states).state !== 'abgeschlossen'
                  "
                  v-model="createdAt"
                  :disabled="nextState(lead.states).state === 'abgeschlossen' && (successfully === true && !value)"
                  :min="useDateFns().formatDate(currentState(lead.states).createdAt as Date, 'yyyy-MM-dd') as any"
                  @update:model-value="createState"
                />
              </v-fade-transition>
            </div>
          </v-fade-transition>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-btn>
</template>

<style lang="scss" scoped>

</style>
