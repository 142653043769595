<script lang="ts" setup>
import { UseDraggable as Draggable } from '@vueuse/components'

const {
  leadId,
} = defineProps<{
  leadId: string
}>()

const emit = defineEmits<{
  (e: 'createdTask'): void
}>()

const task = ref<Task>({
  description: null,
  expirationAt: null,
  priority: 'medium',
  status: 'open',
  title: '',
  type: 'default',
})

function resetTask() {
  task.value = {
    description: null,
    expirationAt: null,
    priority: 'medium',
    status: 'open',
    title: '',
    type: 'default',
  }
}

const isPending = ref(false)

const dialog = ref(false)
const { xs } = useDisplay()

const handle = ref<HTMLElement | null>(null)

const { height, width } = useDisplay()
const initialValue = computed(() => {
  return {
    x: xs.value ? 0 : (width.value / 2) - 300 + 48,
    y: (height.value / 2) - 187 + 48,
  }
})

async function createTask() {
  try {
    isPending.value = true
    await useLeadStore().createLeadTask(leadId, task.value)
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    closeDialog()
    emit('createdTask')
  }
}

function resetForm() {
  resetTask()
}

function closeDialog() {
  resetForm()
  dialog.value = false
}

const windowId = 'createTask' // Eindeutige ID für jedes Fenster
const windowManagerStore = useWindowManagerStore()
const { activeWindowId } = storeToRefs(windowManagerStore)
const zIndex = computed(() => windowManagerStore.windows[windowId] || 100)

function bringToFront() {
  windowManagerStore.bringToFront(windowId)
}

watch(() => dialog.value, (value) => {
  if (value)
    bringToFront()
})
</script>

<template>
  <div>
    <span @click="dialog = !dialog">
      <slot>
        <v-btn
          size="small"
          variant="text"
        >
          <Icon
            name="tabler:plus"
            size="24"
          />
          erstellen
          <v-tooltip
            activator="parent"
            content-class="elevation-0 rounded-xl text-body-1"
            location="top"
            text="Erinnerung "
          />
        </v-btn>
      </slot>
    </span>
    <teleport to="body">
      <v-fade-transition>
        <Draggable
          :initial-value="initialValue"
          :prevent-default="false"
          :handle="handle"
          style="
          position: fixed;
        "
          :style="{ zIndex }"
          @click="bringToFront"
          @move="bringToFront"
        >
          <v-card
            v-if="dialog"
            border="surface thin"
            class="dialog-bottom d-flex flex-column"
            :width="false ? '100%' : '600px'"
            height="374px"
          >
            <v-hover v-slot="{ isHovering, props }">
              <v-card-title
                ref="handle"
                class="py-2 font-weight-light d-flex align-center justify-space-between cursor-move ga-2"
                :class="{
                  'bg-surface-variant': activeWindowId !== windowId && !isHovering,
                  'bg-surface-light': activeWindowId === windowId || isHovering,
                }"
                v-bind="props"
              >
                <span class="d-flex align-center ga-2 flex-grow-1">
                  <Icon v-if="!isPending" name="tabler:calendar-plus" size="24" />
                  <Icon v-else class="text-primary" name="svg-spinners:pulse-multiple" size="24" />
                  Aufgabe anlegen
                </span>
                <v-btn
                  aria-label="schließen"
                  icon
                  size="x-small"
                  variant="text" @click.stop="closeDialog"
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:x"
                    size="18"
                  />
                </v-btn>
              </v-card-title>
            </v-hover>
            <v-divider />
            <div class="pa-4">
              <v-row class="pa-4 ">
                <v-col cols="6" class="pa-0 pr-1">
                  <v-select
                    v-model="task.status"
                    class="mb-2"
                    :items="[
                      { title: 'Offen', value: 'open' },
                      { title: 'In Bearbeitung', value: 'in-progress' },
                      { title: 'Erledigt', value: 'completed' },
                      { title: 'Abgebrochen', value: 'canceled' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Status"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pl-1">
                  <v-select
                    v-model="task.priority"
                    class="mb-2"
                    :items="[
                      { title: 'gering', value: 'low' },
                      { title: 'normal', value: 'medium' },
                      { title: 'hoch', value: 'high' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Priorität"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pr-1">
                  <v-select
                    v-model="task.type"
                    class="mb-2"
                    :items="[
                      { title: 'Standard', value: 'default' },
                      { title: 'Anruf', value: 'call' },
                    ]"
                    destiny="compact"
                    hide-details
                    label="Typ"
                    item-value="value"
                    item-title="title"
                    rounded="lg"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pl-1" style="height:48px">
                  <InputDateTime
                    :date-props="{ min: new Date() }"
                    :menu-props="{ location: 'bottom center', width: '328px' }"
                    :loading="isPending"
                    @update:model-value="task.expirationAt = $event.toISOString() as Date"
                  >
                    <v-btn
                      block
                      color="default"
                      height="40px"
                      rounded="lg"
                      variant="outlined"
                    >
                      {{ task.expirationAt ? useDateFns().formatDate(task.expirationAt, 'dd.MM.yyyy HH:mm') : 'Datum eingeben' }}
                    </v-btn>
                  </InputDateTime>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    v-model="task.title"
                    class="mb-2"
                    density="compact"
                    autofocus
                    hide-details
                    placeholder="Titel"
                    rounded="lg"
                  />
                  <v-textarea
                    v-model="task.description"
                    auto-grow
                    density="compact"
                    hide-details
                    placeholder="Beschreibe deine Aufgabe"
                    rounded="lg"
                    rows="3"
                    max-rows="5"
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />

            <v-sheet
              class="d-flex align-center justify-start pa-2 ga-2"
              color="transparent"
              elevation="0"
            >
              <v-btn
                class="ml-auto mb-1 font-weight-light"
                :disabled="isPending || !task.title"
                variant="tonal"
                @click="createTask"
              >
                erstellen
              </v-btn>
            </v-sheet>
          </v-card>
        </Draggable>
      </v-fade-transition>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>

</style>
