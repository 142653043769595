<script lang="ts" setup>
interface MenuProps {
  openOnHover?: boolean | undefined
  openDelay?: number | string | undefined
  location?: any
  activator?: any
  width?: number | string | undefined
}

interface DateProps {
  min?: Date
  max?: Date
}
interface TimeProps {
  min?: string
  max?: string
}

const {
  dateProps,
  loading,
  menuProps,
  timeProps,
} = defineProps<{
  menuProps?: MenuProps
  dateProps?: DateProps
  timeProps?: TimeProps
  loading?: boolean
}>()

defineEmits(['createTask'])

const date = defineModel<Date | undefined>()
const time = ref('00:00')

// function setToday() {
//   date.value = new Date()
// }

const tab = ref('date')
const menu = ref(false)
</script>

<template>
  <div>
    <slot>{{ date }}</slot>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      persistent
      activator="parent"
      transition="scale-transition"
      content-class="pa-2"
      v-bind="menuProps"
      @update:model-value="tab = 'date'"
    >
      <v-card
        class="pa-2 rounded-lg"
        :width="menuProps?.width"
      >
        <v-window v-model="tab">
          <v-window-item value="date" transition="fade" reverse-transition="fade">
            <v-date-picker
              v-model="date"
              hide-header
              :show-adjacent-months="false"
              v-bind="dateProps"
              @update:model-value="tab = 'time'"
            >
            <!-- <template #actions>
              <div class="d-flex align-center justify-center w-100">
                <v-btn
                  aria-label="heute"
                  class="px-4 py-2"
                  color="primary"
                  variant="tonal"
                  @click="setToday"
                >
                  heute
                </v-btn>
              </div>
            </template> -->
            </v-date-picker>
          </v-window-item>

          <v-window-item value="time" transition="fade" reverse-transition="fade">
            <v-time-picker
              v-model="time"
              class="pa-0"
              color="primary"
              format="24hr"
              :hide-header="false"
              scrollable
              v-bind="timeProps"
              @update:model-value="
                date = useDateFns().changeTimeOfDate(
                  date as Date,
                  parseInt(time.split(':')[0]),
                  parseInt(time.split(':')[1]),
                )"
            >
              <template #title />
              <template #default />
            </v-time-picker>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-btn
            class="ml-auto"
            icon
            :loading="loading"
            size="small"
            variant="text"
            @click="$emit('createTask', date), menu = false"
          >
            <Icon
              name="tabler:device-floppy"
              size="24"
            />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>

</style>
