<script lang="ts" setup>
const {
  min,
} = defineProps<{
  min: Date
}>()

const emit = defineEmits(['update:modelValue'])

const createdAt = defineModel<Date>('createdAt')

function update() {
  // console.log('updateState child', createdAt.value)
  emit('update:modelValue', createdAt.value)
}
function setToday() {
  createdAt.value = new Date()
  update()
}
</script>

<template>
  <v-date-picker
    v-model="createdAt"
    hide-header
    :min
    :max="new Date()"
    :show-adjacent-months="false"
    v-bind="$attrs"
    @update:model-value="update"
  >
    <template #actions>
      <div class="d-flex align-center justify-center w-100">
        <v-btn
          aria-label="heute"
          class="px-4 py-2"
          color="primary"
          variant="tonal"
          v-bind="$attrs"
          @click="setToday"
        >
          heute
        </v-btn>
      </div>
    </template>
  </v-date-picker>
</template>

<style lang="scss" scoped>

</style>
